import { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { server_url } from "../../environmentVariables/env";
// import qs from 'qs'
import QueryString from "qs";
import { beautifyPages } from "../../helpers/beautifyPages";

const cmsContext = createContext();

export const useCMS = () => useContext(cmsContext);

export const CMSProvider = ({ children }) => {
    const [blogs, setBlogs] = useState([]);
    const [pages, setPages] = useState([]);

    useEffect(() => {
        initializeCMSData()

        return () => {
            setBlogs([]);
        }
    }, [])

    const initializeCMSData = async () => {
        try {
            const _blogs = await fetchBlogs();
            setBlogs(_blogs);

            // const _pages = await fetchPages();
            // setPages(_pages);

            const _pages = await fetchPagesV1();
            setPages(_pages)
        } catch (e) {
            console.error(e);
        }
    }

    const fetchBlogs = async () => {
        // Blogs
        const stringifiedQuery = QueryString.stringify(
            {
                where: {
                    status: { equals: "published" },
                    type: { exists: false }
                },
            },
            { addQueryPrefix: true }
        );

        return await axios.get(`${server_url}/api/blogs${stringifiedQuery}`)
            .then(response => response.data.docs)
            .then(data => {
                // console.log(data);
                const _blogs = data
                    .map((blog) => {
                        return {
                            ...blog,
                            createdAt: new Date(blog.createdAt)
                        }
                    })
                    .sort((a, b) => a.createdAt - b.createdAt)
                // setBlogs(() => _blogs);
                return _blogs
            })
            .catch(err => {
                return err;
            });
    }

    const fetchPagesV1 = async () => {
        const stringifiedQuery = QueryString.stringify(
            {
                where: {
                    status: { equals: "published" },
                    type: { exists: true }
                },
            },
            { addQueryPrefix: true }
        );

        return await axios.get(`${server_url}/api/blogs${stringifiedQuery}`)
            .then(response => response.data.docs)
            .then(data => {
                // console.log(data);
                const _blogs = data
                    .map((blog) => {
                        return {
                            ...blog,
                            createdAt: new Date(blog.createdAt)
                        }
                    })
                    .sort((a, b) => a.createdAt - b.createdAt)
                // setBlogs(() => _blogs);
                return _blogs
            })
            .catch(err => {
                return err;
            });
    }

    const fetchPages = async () => {
        // Pages
        const query = {
            status: {
                // property name to filter on
                equals: "published", // operator to use and value to compare against
            },
        };

        const stringifiedQuery = QueryString.stringify(
            {
                where: {
                    status: { equals: "published" }
                } // ensure that `qs` adds the `where` property, too!
            },
            { addQueryPrefix: true }
        );

        return await axios.get(`${server_url}/api/pages${stringifiedQuery}`)
            .then(response => response.data.docs)
            .then(data => {
                const _pages = data
                    .map(page => {
                        return {
                            ...page,
                            createdAt: new Date(page.createdAt)
                        }
                    })
                    .sort((a, b) => a.createdAt - b.createdAt)
                return beautifyPages(_pages);
            })
            .catch(err => err)
    }

    let value = {
        blogs,
        pages,
    }

    return (
        <cmsContext.Provider value={value} >
            {children}
        </cmsContext.Provider>
    )
}