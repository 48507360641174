import React, {
    useState,
    useEffect,
    Fragment,
    useRef
} from 'react'

function AddressForm({
    form,
    setForm,
    errors,
    type = "shipping_address",
    countriesOptions
}) {
    function onChange(e) {
        const { name, value } = e.target;
        setForm((prevState) => ({
            ...prevState,
            [type]: {
                ...prevState[type],
                [name]: value
            }
        }))
    }

    // useEffect(() => {
    //     console.log(form);
    // }, [form]);

    // useEffect(() => {
    //     console.log(errors);
    // }, [errors])

    return (
        <Fragment>
            <div className="row">
                <div className="col-lg-6 col-md-6">
                    <div className="billing-info mb-20">
                        {/* <p className='fw-bold'>
                            First Name
                            <span
                                className='mx-1'
                                style={{
                                    color: "var(--theme-color-one)"
                                }}
                            >
                                <i className="fa-solid fa-asterisk"></i>
                            </span>
                        </p> */}
                        <input
                            value={form[type]["first_name"]}
                            placeholder='First Name'
                            type="text"
                            name='first_name'
                            className={errors[type]["first_name"]?.[0] ? "error" : ""}
                            onChange={onChange}
                            style={errors[type]["first_name"]?.[0] ? { border: "1px solid red" } : {}}
                        />
                        <p className="text-danger small">{errors[type]["first_name"]?.[0] || ""}</p>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6">
                    <div className="billing-info mb-20">
                        {/* <p className='fw-bold'>
                            Last Name
                            <span
                                className='mx-1'
                                style={{
                                    color: "var(--theme-color-one)"
                                }}
                            >
                                <i className="fa-solid fa-asterisk"></i>
                            </span>
                        </p> */}
                        <input
                            value={form[type]["last_name"]}
                            type="text"
                            placeholder='Last Name'
                            name='last_name'
                            onChange={onChange}
                            style={errors[type]["last_name"]?.[0] ? { border: "1px solid red" } : {}}
                            className={errors[type]["last_name"]?.[0] ? "error" : ""}
                        />
                        <p className="text-danger small">{errors[type]["last_name"]?.[0] || ""}</p>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="billing-info mb-20">
                        {/* <p className='fw-bold'>
                            Address
                            <span
                                className='mx-1'
                                style={{
                                    color: "var(--theme-color-one)"
                                }}
                            >
                                <i className="fa-solid fa-asterisk"></i>
                            </span>
                        </p> */}
                        <input
                            className={`billing-address ${errors[type]["address_1"]?.[0] ? "error" : ""}`}
                            placeholder="Address"
                            type="text"
                            name='address_1'
                            value={form[type]["address_1"]}
                            onChange={onChange}
                            style={errors[type]["address_1"]?.[0] ? { border: "1px solid red" } : {}}
                        />
                        <p className="text-danger small">{errors[type]["address_1"]?.[0] || ""}</p>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="billing-info mb-20">
                        {/* <p className='fw-bold'>
                            Additional address information {"("}optional{")"}
                            <span
                                className='mx-1'
                                style={{
                                    color: "var(--theme-color-one)"
                                }}
                            >
                            </span>
                        </p> */}
                        <input
                            className={`billing-address ${errors[type]["address_2"]?.[0] ? "error" : ""}`}
                            placeholder="Additional information (optional)"
                            type="text"
                            name='address_2'
                            value={form[type]["address_2"]}
                            onChange={onChange}
                            style={errors[type]["address_2"]?.[0] ? { border: "1px solid red" } : {}}
                        />
                        <p className="text-danger small">{errors[type]["address_2"]?.[0] || ""}</p>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="billing-info mb-20">
                        {/* <p className='fw-bold'>
                            Town / City
                            <span
                                className='mx-1'
                                style={{
                                    color: "var(--theme-color-one)"
                                }}
                            >
                                <i className="fa-solid fa-asterisk"></i>
                            </span>
                        </p> */}
                        <input
                            value={form[type]["city"]}
                            type="text"
                            placeholder='Town / City'
                            name='city'
                            onChange={onChange}
                            style={errors[type]["city"]?.[0] ? { border: "1px solid red" } : {}}
                            className={errors[type]["city"]?.[0] ? "error" : ""}
                        />
                        <p className="text-danger small">{errors[type]["city"]?.[0] || ''}</p>
                    </div>
                </div>

                {/* <div className="col-lg-12">
                    <div className="billing-info mb-20">
                        <p className='fw-bold'>
                            Region / State
                            <span
                                className='mx-1'
                                style={{
                                    color: "var(--theme-color-one)"
                                }}
                            >
                                <i className="fa-solid fa-asterisk"></i>
                            </span>
                        </p>
                        <input
                            value={form[type]["province"]}
                            type="text"
                            placeholder='Province'
                            name='province'
                            onChange={onChange}
                            style={errors[type]["province"]?.[0] ? { border: "1px solid red" } : {}}
                            className={errors[type]["province"]?.[0] ? "error" : ""}
                        />
                        <p className="text-danger small">{errors[type]["province"]?.[0] || ''}</p>
                    </div>
                </div> */}

                <div className="col-lg-6 col-md-6">
                    <div className="billing-info mb-20">
                        {/* <p className='fw-bold'>
                            Postal code / ZIP
                            <span
                                className='mx-1'
                                style={{
                                    color: "var(--theme-color-one)"
                                }}
                            >
                                <i className="fa-solid fa-asterisk"></i>
                            </span>
                        </p> */}
                        <input
                            type="text"
                            value={form[type]["postal_code"]}
                            placeholder='Postal Code'
                            name="postal_code"
                            onChange={onChange}
                            style={errors[type]["postal_code"]?.[0] ? { border: "1px solid red" } : {}}
                            className={errors[type]["postal_code"]?.[0] ? "error" : ""}
                        />
                        <p className="text-danger small">{errors[type]["postal_code"]?.[0] || ""}</p>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6">
                    <div className="billing-info mb-20">
                        {/* <p className='fw-bold'>
                            Phone
                            <span
                                className='mx-1'
                                style={{
                                    color: "var(--theme-color-one)"
                                }}
                            >
                                <i className="fa-solid fa-asterisk"></i>
                            </span>
                        </p> */}
                        <input
                            value={form[type]["phone"]}
                            type="tel"
                            placeholder='Phone'
                            name='phone'
                            onChange={onChange}
                            className={errors[type]["phone"]?.[0] ? "error" : ""}
                            style={errors[type]["phone"]?.[0] ? { border: "1px solid red" } : {}}
                        />
                        <p className="text-danger small">{errors[type]["phone"]?.[0] || ""}</p>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="billing-select mb-20">
                        {/* <p className='fw-bold'>
                            Country
                            <span
                                className='mx-1'
                                style={{
                                    color: "var(--theme-color-one)"
                                }}
                            >
                                <i className="fa-solid fa-asterisk"></i>
                            </span>
                        </p> */}
                        <select
                            placeholder='Country'
                            value={form[type]["country_code"]?.label}
                            name='country_code'
                            className={errors[type]["country_code"]?.[0] ? "error" : ""}
                            // className=''
                            style={errors[type]["country_code"]?.[0] ? 
                            { 
                                border: "1px solid red",
                                padding: "12px 8px",
                            } : 
                            {
                                padding: "12px 8px",    
                                border: "1px solid #eceff8"
                            }}
                            onChange={(e) => {
                                const { name, value } = e.target;
                                // console.log(name, value);
                                const selectedOption = countriesOptions.find(op => op.label === value);
                                setForm((prevState) => ({
                                    ...prevState,
                                    [type]: {
                                        ...prevState[type],
                                        [name]: selectedOption
                                    }
                                }))
                            }}
                        >
                            <option>Select a country</option>
                            {countriesOptions.map((el, idx) => (
                                <option
                                    key={idx}
                                >
                                    {el.label}
                                </option>
                            ))}
                        </select>
                        <p className="text-danger small">{errors[type]["country_code"]?.[0] || ""}</p>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default AddressForm