import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useMedusa } from '../../../../context/medusa/MedusaContext';
import Checkbox from '../../../checkbox/checkbox';

function UserDetails({
    form,
    setForm,
    errors,
    createAccount,
    setCreateAccount
}) {
    const navigate = useNavigate();

    const {
        cart,
        // user
    } = useMedusa();

    function onChange(e) {
        const { name, value } = e.target;
        setForm((prevState) => ({
            ...prevState,
            [name]: value
        }));
    }

    return (
        <div>
            <div className='col-lg-12 mb-3'>
                <div className="billing-info mb-20">
                    {/* <p className='fw-bold'>
                        Email
                        <span
                            className='mx-1'
                            style={{
                                color: "var(--theme-color-one)"
                            }}
                        >
                            <i class="fa-solid fa-asterisk"></i>
                        </span>
                    </p> */}
                    <input
                        className={errors["email"]?.[0] ? "error" : ""}
                        value={form["email"]}
                        type="text"
                        placeholder='Email'
                        name="email"
                        onChange={onChange}
                        style={errors["email"]?.[0] ? { border: "1px solid red" } : {}}
                    />
                    <p class="text-danger small">
                        {errors?.email[0] || ""}
                    </p>
                </div>
            </div>
            <div className='col-lg-12 d-flex flex-row align-items-center test-border/'>
                <div className='col-lg-6 d-flex flex-row align-items-center test-border/'>
                    <div>
                        <Checkbox
                            className={"me-2"}
                            value={createAccount}
                            onChange={() => setCreateAccount(!createAccount)}
                        />
                    </div>
                    <p> Auto-create account for tracking and order history. </p>
                </div>
                <div className='col-lg-6 d-flex flex-row align-items-center justify-content-end test-border/'>
                    <p className='fw-bold mt-2 mx-2'>Already a customer? </p>
                    <button
                        className="btn-secondary"
                        type='button'
                        onClick={() => {
                            if (cart) {
                                navigate(`/login-register/login?email_id=${(form.email || "")}&cart_id=${cart.id}`)
                            }
                        }}
                    >
                        Login
                    </button>
                </div>
            </div>
            {/* <div className='d-flex flex-column align-items-end'>
                <div className='d-flex flex-row align-items-center my-2'>
                </div>
            </div> */}
        </div>
    )
}

export default UserDetails