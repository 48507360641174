import React, { useState, useEffect } from 'react'
import { countries as _countries } from '../../../../data/Countries';
import axios from 'axios';
import { server_url } from '../../../../environmentVariables/env';

function DeliveryDetails({ cart, onClickEdit }) {
    const locationId = cart?.items?.find(item => item.metadata?.stock_location_id)?.metadata?.stock_location_id;

    const [location, setLocation] = useState(null);

    useEffect(() => {
        fetchLocation(locationId);
    }, [locationId])

    const fetchLocation = async (location_id) => {
        if (!location_id) return null;
        try {
            return await axios.get(server_url + '/store/store-locations')
                .then(response => {
                    return response.data
                })
                .then(({ stock_locations }) => {
                    let location = stock_locations
                        .find(loc => loc.id === location_id);
                    setLocation(location);
                    return location;
                })
        } catch (e) {
            console.error(e);
        }
    }

    if (!cart) return <></>
    return (
        <div className='container d-flex flex-column align-items-end'>
            <div className='d-flex w-100 flex-row justify-content-start mb-3'>
                <button
                    className="btn btn-link text-decoration-none text-decoration-underline"
                    type='button'
                    style={{
                        fontSize: "15px"
                    }}
                    onClick={onClickEdit}
                >
                    Edit delivery details
                </button>
            </div>
            {(
                (cart?.items || []).filter((item) => !item["metadata"]?.["stock_location_id"]).length > 0
            ) && (
                    <div className='mb-3'>
                        <p className='fw-bold text-end'>Deliver my order to</p>
                        <p className='text-end'>
                            {(() => getAddressString(cart.shipping_address))()}
                        </p>
                    </div>
                )}
            <div className='mb-3'>
                {/* {isSameAddress(cart.shipping_address, cart.billing_address) ? (
                    <div>
                        <p className='fw-bold text-end'> Billing Address</p>
                        <p className='text-end'> Billing address matches delivery address </p>
                    </div>
                ) : (
                    <div>
                        <p className='fw-bold'>Billing Address</p>
                        <p className='text-end'>
                            {(() => getAddressString(cart.shipping_address))()}
                        </p>
                    </div>
                )} */}
                <div>
                    <p className='fw-bold text-end'>Billing Address</p>
                    <p className='text-end'>
                        {(() => getAddressString(cart.shipping_address))()}
                    </p>
                </div>
            </div>

            {(
                location && 
                (cart?.items || []).filter((item) => item.metadata?.stock_location_id).length > 0
            ) && (
                    <div className='mb-3'>
                        <div>
                            <p className='fw-bold text-end'>Collection Information</p>
                            <p className='text-end'>
                                {(() => getAddressString(location.address))()}
                            </p>
                        </div>
                    </div>
                )}

            <div>
                <div>
                    <p className='text-end'>Delivery method: {cart.shipping_methods?.[0]?.shipping_option?.name}</p>
                </div>
            </div>
        </div>
    )
}

function getAddressString(address) {
    // const countriesOptions = useMemo(() => {
    // return _countries?.length > 0 ? _countries.map(country => ({ label: country.label, value: country.value.toLowerCase() })) : []
    // }, [_countries]);

    const countriesOptions = _countries?.length > 0 ? _countries.map(country => ({ label: country.label, value: country.value.toLowerCase() })) : []

    const keysToKeep = [
        "first_name",
        "last_name",
        "address_1",
        "address_2",
        "city",
        "province",
        "postal_code",
        "country_code",
        "phone"
    ]
    const keys = Object.keys(address).filter(key => keysToKeep.includes(key));

    return (
        <>
            {keysToKeep.map((key, idx) => {
                if (address[key]) {
                    if (key === "country_code") {
                        return <span key={idx}> {countriesOptions.find(country => country.value === address[key])?.label} <br /> </span>
                    }
                    return <span key={idx}> {address[key]} {key === "first_name" ? "" : <br />}</span>
                } else return ""
            })}
        </>
    )
}

function isSameAddress(address_1, address_2) {
    if (!address_1 || !address_2) return false;
    const keysToKeep = [
        "first_name",
        "last_name",
        "address_1",
        "address_2",
        "city",
        "country_code",
        "province",
        "postal_code",
        // "phone"
    ]

    const keys_1 = Object.keys(address_1).filter(key => keysToKeep.includes(key));
    const keys_2 = Object.keys(address_2).filter(key => keysToKeep.includes(key));

    for (let key of keysToKeep) {
        if (address_1[key] !== address_2[key]) {
            return false;
        }
    }

    return true;
}

export default DeliveryDetails